import React from "react"

import { Helmet } from "react-helmet"

import Payment from "../../components/payment"

const PaymentPage = () => {
  return (
    <>
      <Helmet>
        <title>Betalen - Todocards.nl</title>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Payment />
    </>
  )
}

export default PaymentPage
