import React, { useEffect } from "react"

import { navigate } from "gatsby"
import { CircularProgress, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { ViewDispatchActions, ViewProps } from "./payment-types"
import { getOrderStatus } from "../../api/actions"
import { OrderStatus } from "../../api/sync"

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100vw`,
    height: `100vh`,
  },

  inner: {
    textAlign: `center`,
  },

  mb2: {
    marginBottom: theme.spacing(2),
  },
}))

const PaymentView = ({
  orderRef,
  cartReset,
  orderReset,
  showAlert,
}: ViewProps & ViewDispatchActions) => {
  const classes = useStyles()

  useEffect(() => {
    let isBusy = false
    const interval = setInterval(() => {
      if (isBusy) {
        return
      }

      isBusy = true
      getOrderStatus(
        orderRef,
        `De betaling kon niet gecontroleerd worden, er is een onbekende fout opgetreden.`,
        `De betaling kon niet gecontroleerd worden, controleer de verbinding met internet.`
      ).then(
        response => {
          isBusy = false

          switch (response.status) {
            case OrderStatus.PAID:
              cartReset()
              orderReset()
              navigate(`/bestellen/afgerond`)
              break

            case OrderStatus.CANCELED:
              showAlert(
                `Betaling geannuleerd`,
                `De betaling is geannuleerd, probeer het nog een keer.`
              )

              navigate(`/bestellen/gegevens`)
              break

            case OrderStatus.EXPIRED:
              showAlert(
                `Betaling verlopen`,
                `De betaling is verlopen, probeer het nog een keer.`
              )

              navigate(`/bestellen/gegevens`)
              break

            case OrderStatus.FAILED:
              showAlert(
                `Betaling mislukt`,
                `De betaling is mislukt, probeer het nog een keer.`
              )

              navigate(`/bestellen/gegevens`)
              break
          }
        },
        () => {
          navigate(`/`)
        }
      )
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <div className={classes.mb2}>
          <CircularProgress />
        </div>

        <Typography variant="h1">Betaling controleren</Typography>
      </div>
    </div>
  )
}

export default PaymentView
