import { CartActionType, CartResetAction, CartUpdateAction } from "./types"
import { Product } from "../../api/sync"

export const addProduct = (product: Product): CartUpdateAction => ({
  type: CartActionType.CART_ADD_PRODUCT,
  payload: {
    product,
  },
})

export const removeProduct = (product: Product): CartUpdateAction => ({
  type: CartActionType.CART_REMOVE_PRODUCT,
  payload: {
    product,
  },
})

export const increaseQuantity = (product: Product): CartUpdateAction => ({
  type: CartActionType.CART_INCREASE_QUANTITY,
  payload: {
    product,
  },
})

export const decreaseQuantity = (product: Product): CartUpdateAction => ({
  type: CartActionType.CART_DECREASE_QUANTITY,
  payload: {
    product,
  },
})

export const reset = (): CartResetAction => ({
  type: CartActionType.CART_RESET,
})
