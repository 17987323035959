import { shop } from "./sync"
import { OrderDetails } from "../redux/order/types"
import { CartState } from "../redux/cart/types"
import store from "../redux/store"
import { showAlert } from "../redux/alert/actions"
import { CreateOrderResponse, OrderStatusResponse } from "./types"

const dispatchShowAlert = (title: string, message: string): void => {
  store.dispatch(showAlert(title, message))
}

const call = <T>(
  url: string,
  body: {},
  errorMessage: string,
  connectionErrorMessage: string
): Promise<T> => {
  return fetch(`${process.env.GATSBY_API_URL}/${url}`, {
    headers: {
      "content-type": `application/json; charset=utf-8`,
    },
    body: JSON.stringify({
      shop,
      ...body,
    }),
    method: `POST`,
  })
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response)
      }
      return Promise.resolve(response.json())
    })
    .catch(error => {
      if (error instanceof Response) {
        dispatchShowAlert(`Er is een fout opgetreden`, errorMessage)
      } else {
        dispatchShowAlert(`Geen verbinding`, connectionErrorMessage)
      }

      return Promise.reject()
    })
}

export const createOrder = (
  details: OrderDetails,
  cart: CartState,
  redirectUrl: string,
  errorMessage: string,
  connectionErrorMessage: string
): Promise<CreateOrderResponse> => {
  return call(
    `order`,
    {
      ...details,
      rows: cart,
      redirectUrl,
    },
    errorMessage,
    connectionErrorMessage
  )
}

export const getOrderStatus = (
  ref: string,
  errorMessage: string,
  connectionErrorMessage: string
): Promise<OrderStatusResponse> => {
  return call(
    `order/status`,
    {
      ref,
    },
    errorMessage,
    connectionErrorMessage
  )
}

export const createContactMessage = (
  email: string,
  message: string,
  errorMessage: string,
  connectionErrorMessage: string
): Promise<OrderStatusResponse> => {
  return call(
    `contact_message`,
    {
      email,
      message,
    },
    errorMessage,
    connectionErrorMessage
  )
}

export const createReturnRequest = (
  name: string,
  email: string,
  orderRef: string,
  products: string,
  reason: string,
  errorMessage: string,
  connectionErrorMessage: string
): Promise<OrderStatusResponse> => {
  return call(
    `return_request`,
    {
      name,
      email,
      orderRef,
      products,
      reason,
    },
    errorMessage,
    connectionErrorMessage
  )
}
