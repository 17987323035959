import { connect } from "react-redux"

import { State } from "../../redux/types"
import { ViewProps } from "./payment-types"
import PaymentView from "./payment-view"
import { showAlert } from "../../redux/alert/actions"
import { reset as cartReset } from "../../redux/cart/actions"
import { reset as orderReset } from "../../redux/order/actions"

const mapStateToProps = (state: State): ViewProps => {
  return {
    orderRef: state.order.ref,
  }
}

export default connect(mapStateToProps, { cartReset, orderReset, showAlert })(
  PaymentView
)
